<template>
    <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
            <v-row class="mt-2">
                <v-col cols="12">
                    <project-info-card
                        v-if="recipe.id"
                        :project="recipe"
                        type="recipe"
                        card-width="100%"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="540"
                    >
                    </project-info-card>
                    <div class="mt-10">
                        <v-alert
                            text
                            color="accent darken-1"
                            icon="fa-solid fa-kitchen-set"
                            border="left"
                        >
                            {{ recipe.description }}
                        </v-alert>
                    </div>
                </v-col>
                <v-col cols="12" class="d-md-flex justify-md-space-between">
                    <requirements
                        v-if="hasIngredients"
                        :title="$t(`Nutrition.ingredients`)"
                        :items="recipe.ingredients"
                        color="nutrition"
                    ></requirements>
                    <v-card
                        v-if="recipe.image_url"
                        flat
                        width="50%"
                        color="appBackground"
                    >
                        <v-card-title>
                            <h3>{{ $t(`Nutrition.steps`) }}</h3>
                        </v-card-title>
                        <v-timeline reverse dense>
                            <v-timeline-item
                                v-for="step in recipe.steps"
                                :key="step.id"
                                color="nutrition"
                            >
                                <v-card>
                                    <v-card-text>
                                        {{ step.description }}
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getRecipe } from "@/api/nutritionApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import Requirements from "@/components/General/Requirements.vue";
import ViewStepper from "@/components/General/ViewStepper.vue";
export default {
    name: "RecipeInfo",

    components: {
        ProjectInfoCard,
        Requirements,
        ViewStepper,
        ViewStepsBtn: () => import(`@/components/General/ViewStepsBtn.vue`),
    },

    data() {
        return {
            stepper: 1,
            recipe: {},
            cardHeight: CONFIG.card_heigth,
            mediaHeight: CONFIG.media_height,
        };
    },

    computed: {
        steps() {
            return this.recipe.steps.length;
        },
        hasIngredients() {
            return this.recipe?.ingredients ? true : false;
        },
        hasSteps() {
            return this.recipe?.steps?.length > 0 ? true : false;
        },
    },

    methods: {
        async handleRecipe() {
            const payload = {
                id: this.$route.params.id,
            };
            const { response, error } = await withAsync(getRecipe, payload);

            if (error) {
                return;
            }

            this.recipe = response.data.data;
        },
    },

    created() {
        this.handleRecipe();
    },
};
</script>

<style lang="scss" scoped></style>
